import './App.css';
//change this via environment variables, default is located in .env-file
const Routing = (require(process.env.REACT_APP_ROUTEFILE)).default;

export default function App() {
  return (
    <div>
      {process.env.NODE_ENV !== 'production' ? "DevBuild, Routefile: "+process.env.REACT_APP_ROUTEFILE:""}
      <Routing></Routing>
    </div>
  );
}
