import { Field, useFormikContext } from "formik";
import React from "react";
import { useState } from "react";
import BasicField from './BasicField'
import { ErrorBoundary } from 'react-error-boundary'
import ErrorBorder from "./ErrorBorder";
import { useEffect } from "react";

function ErrorFallback(msg: string) {
    return (
        (error, resetErrorBoundary) => {
            return (
                <div role="alert">
                    <p>{msg}</p>
                    <pre>{error.message}</pre>
                    <button onClick={resetErrorBoundary}>Try again</button>
                </div>
            )
        })
}
export default function FileUploadRow(props: { name: string, onchanged: (ev) => void, resetCallback: () => void }) {
    /** 
 * React Componenten werden neu gerendert wenn sich ihr Key ändert.
*/
    const [random, setKey] = useState(Math.random().toString(36))
    const formikProps = useFormikContext();

    let reset = () => {
        let randomString = Math.random().toString(36);
        setKey(randomString);
        //formikProps.setFieldValue(props.name, "");
        props.resetCallback();
    }

    useEffect(() => props.resetCallback(), [random])

    return (
        <ErrorBorder msg={"foo"}>
            <BasicField fname={props.name}>
                <ErrorBoundary FallbackComponent={ErrorFallback("empty")}>
                    <>
                        <div>
                            <ErrorBoundary FallbackComponent={ErrorFallback("input")}>

                                <input
                                    id={props.name}
                                    name={props.name}
                                    key={random}
                                    type="file"
                                    className="mt-4"
                                    onChange={props.onchanged(formikProps)}
                                    multiple />
                            </ErrorBoundary>
                            <button type="button" className="mt-4 bg-gray-600 text-white w-28 h-8 rounded" onClick={reset}>Delete files</button>
                        </div>
                    </>
                </ErrorBoundary>
            </BasicField>
        </ErrorBorder>
    );
}
