import { Formik } from "formik";
import Header from "./Header";
import React from 'react'
import { ScrollToError } from "./scrollToError";

export default function BasicForm(props: { submitHandler: (any) => any, validationSchema?: any, title: string, initialValues: any, children: JSX.Element }) {

    return (
        <div className="md:w-10/12 lg:w-9/12 xl:w-6/12 shadow-xl bg-white bg-opacity-90">
            <Header title={props.title} />
            <div className="mx-8 mt-4">
                <Formik
                    initialValues={props.initialValues}
                    onSubmit={props.submitHandler}
                    validationSchema={props.validationSchema}
                >
                    {(formikProps) => (
                        <>
                            {props.children}
                            <ScrollToError />
                            {process.env.NODE_ENV !== 'production' &&
                                <pre>{JSON.stringify(formikProps, undefined, "  ")}</pre>
                            }
                            {formikProps.isSubmitting ? (<progress></progress>) : ""}
                        </>
                    )}
                </Formik>
            </div>
        </div>
    )
}