import { ErrorMessage } from "formik";
import React from "react";
import ErrorBorder from "./ErrorBorder";

export default function BasicField(props: {
    children: JSX.Element, fname: string
}) {

    return (
        <>
            <ErrorBorder msg="props.children">

                {props.children}
            </ErrorBorder>
            <ErrorBorder msg="ErrorMessage">

                <ErrorMessage
                    component='div'
                    name={props.fname}
                    className="is-invalid"
                    style={{ color: "red", fontSize: "12px" }} />
            </ErrorBorder>
        </>
    );
}