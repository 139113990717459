
export default function Section(props) {
    return (
        <div className="mx-10">
            <h2 className="mt-8 mb-6 text-2xl font-bold leading-tight text-[#054852]">{props.title}</h2>
            {props.subtitle && 
            <div className="">{props.subtitle}</div>}
            {props.children}
        </div>
    )
}