/* eslint-disable import/no-anonymous-default-export */
import { BlockBlobClient, AnonymousCredential, BlobUploadCommonResponse } from '@azure/storage-blob';

import * as Yup from 'yup';

export default (Settings: { [x: string]: any; }, InitialValues: { [x: string]: any; }) => new class Util {
    /*call_blob_directory_logic_app,
    call_sharepoint_logic_app,
    upload_files,
    Require_String,
    Require_Bool,*/

    /**this validation applies to all String/Text Fields unless overwritten in the Formik Component in "Home*.js"*/
    // eslint-disable-next-line
    defaultValidation = Yup.string().max(1500,"Too Long").required("Required").matches(/^[\w\d\s.,\\\/?!#"'()\[\]<>+-_:*;&%§äüö]*$/, "Input may only contain the following characters: A-Za-z0-9,.äöü\\/?!#\"'()[]<>+-_:*;&%§");
    /**Schema using the default validation based on the initial values
    *we've set in the Settings*/
   defaultValidationScheme = {}
   // eslint-disable-next-line
    defaultValidationScheme = (() => {
        Object.keys(InitialValues).forEach(
            (fieldName) =>
                typeof InitialValues[fieldName] === typeof "" ?
                    this.defaultValidationScheme[fieldName] = this.defaultValidation
                    : "")


        return this.defaultValidationScheme
    })()

// TODO should be properly names
    submitHandler(navigate: (arg0: string) => void, setShowLoader: (arg0: boolean) => void) {
        return async (values:{}) => {

            let file_bags = Object.entries(values).filter(([key,val])=>(
                typeof key === typeof "" && 
                key.endsWith('filebag')))
            
            let files = []
            file_bags.forEach(([key, content]) => {
                Object.values(content).forEach((fileArray)=>{
                    files = files.concat(fileArray)
                })
            })

            let valuesWithoutFilebags = {}
            Object.entries(values).filter(([key,val])=>(
                typeof key === typeof "" && 
                !key.endsWith('filebag') &&
                key != "acceptForm" &&
                key != "acceptProcessing"
                )).forEach(
                ([key,val])=> valuesWithoutFilebags[key] = val
                )

            let currentdate = new Date();
            let datetime = currentdate.getDate() + "-"
                + (currentdate.getMonth() + 1) + "-"
                + currentdate.getFullYear() + "-"
                + currentdate.getHours() + "-"
                + currentdate.getMinutes() + "-"
                + currentdate.getSeconds();
            
            let blob_directory_name = `${values.vCompanyName}-${datetime}`;
            if (process.env.NODE_ENV !== 'production') console.log(blob_directory_name);

            this.call_blob_directory_logic_app(blob_directory_name);


            let promise = this.upload_files(files, blob_directory_name);
            
            setShowLoader(true);
            await promise.then(()=>{
                this.call_sharepoint_logic_app(valuesWithoutFilebags,blob_directory_name);
                setShowLoader(false);

                navigate('/success');
            })
        }
    }

    upload_file_to_blob_static_key(file: Buffer | Blob | ArrayBuffer | ArrayBufferView, directory: string) {
        if (!file.name) {
            debugger;
        }

        var login_url = `${Settings['blob_url']}/${Settings['container']}/${encodeURI(directory)}/${encodeURI(file.name)}?${Settings['sas_key']}`;

        if (process.env.NODE_ENV !== 'production') console.log(login_url);

        var block_blob_client = new BlockBlobClient(login_url, new AnonymousCredential());
        const uploadPromise = block_blob_client.uploadData(file);
        uploadPromise.catch((reason) => alert(`Upload of file ${file.name} failed: ${JSON.stringify(reason)}`))
        if (process.env.NODE_ENV !== 'production') console.log(uploadPromise);
        return uploadPromise;
    }

    get_xhr(url: string | URL) {
        let xhr = new XMLHttpRequest();
        xhr.open("POST", url);

        xhr.setRequestHeader("Accept", "application/json");
        xhr.setRequestHeader("Content-Type", "application/json");

        xhr.onload = () => console.log(xhr.responseText);

        return xhr;
    }

    call_blob_directory_logic_app(company_name: string) {
        let xhr = this.get_xhr(Settings['blob_endpoint']);

        let data = JSON.stringify({
            "company_name": company_name,
        });

        xhr.send(data);
    }

    upload_files(files: string | any[], company_name: string) {
        if (process.env.NODE_ENV !== 'production') console.log(files);
        if (process.env.NODE_ENV !== 'production') console.log("uploading files");
        let promArray:Promise<BlobUploadCommonResponse>[] = []
        
        for (let i = 0; i < files.length; i++) {
            const uploadPromise = this.upload_file_to_blob_static_key(files[i], company_name);
            promArray.push(uploadPromise);
        }
        return Promise.all(promArray)
        
    }

    call_sharepoint_logic_app(values: {}, blob_directory_name: string) {
        let xhr = this.get_xhr(Settings['sharepoint_endpoint']);

        let data = JSON.stringify({
            values,
            blob:blob_directory_name

        });

        if (process.env.NODE_ENV !== 'production') console.log(data);

        xhr.send(data);
    }
}(Settings)

export const Require_String = Yup.string().required('Required');
export const Require_Bool = Yup.bool().oneOf([true], 'Accept');



