import React, { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";


export default function ErrorBorder(props:{
    children: ReactNode;
    msg:string
}){
    return (
        <ErrorBoundary FallbackComponent={ErrorFallback(props.msg)}>
            {props.children}
        </ErrorBoundary>
    )
}


function ErrorFallback(msg: string) {
    return (
        (error, resetErrorBoundary) => {
            return (
                <div role="alert">
                    <p>{msg}</p>
                    <pre>{error.message}</pre>
                    <button onClick={resetErrorBoundary}>Try again</button>
                </div>
            )
        })
}