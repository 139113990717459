import Header from './components/Header';
import Section from './components/Section';

export default function Success(props:{title:string}) {
    return (
        <div style={{ backgroundImage: "url(/affimed_background.jpg)" }}
            className="bg-fixed bg-cover bg-no-repeat bg-center h-screen">
            <div className="flex flex-col flex-wrap items-center pt-10">
                <div className="md:w-10/12 lg:w-9/12 xl:w-6/12 shadow-xl bg-white bg-opacity-90">
                    <Header title={props.title}
                     />
                    <div className="mx-8 mt-4 mb-10">
                        <Section
                            title="Successfully uploaded formular">
                            <div className="">
                                Files successfully uploaded and form sent out for signing via Adobe Sign to the specified email address.
                                You will receive an email in the next seconds. Please sign the document <b>within the next 48h</b> to complete this flow.
                            </div>
                        </Section>
                    </div>
                </div>
            </div>
        </div>
    )
}