import { ErrorMessage, Field, useField } from "formik";

export default function CheckBox({ title, ...props }) {
    const [field, meta] = useField(props);

    return (
        <>
            {!(title === "") ?
                (<div className='mt-10'>
                    <label htmlFor={field.name}>{title}</label>
                </div>):""}
            <div className='mt-4'>
                <div>

                    <Field
                        id={field.name}
                        className={`form-control shadow-none ${meta.touched && meta.error && 'is-invalid'}`}
                        {...field}
                        {...props}
                        autoComplete='off' />
                    <label htmlFor={field.name} className="ml-4">{props.label}</label>
                </div>
                <ErrorMessage component='div' name={field.name} style={{ color: "red", fontSize: "12px" }} />
            </div>
        </>
    )
}