import { Form } from 'formik';
import { useNavigate } from 'react-router-dom';
import {
    InputField,
    Section,
    SubmitButton,
    InputTextArea,
    FileUploadField,
    TableField,
    CheckBox
} from '../components/Components';
import * as Yup from 'yup';
import { Settings, InitialValues } from './Settings';
import BasicForm from '../components/BasicForm';
import { useState } from 'react';
import UtilFactory from '../Utilities';
import React from 'react';
let Util = UtilFactory(Settings, InitialValues);

let validationSchema = {
    ...Util.defaultValidationScheme,
    vCompanyName: Yup.string().required('Required').max(40, 'must be less than 40 characters').matches(/^[\w\d\s.,äöü]*$/,"Input may only contain the following characters: A-Za-z0-9,.äöü"),
    acceptForm: Yup.bool().oneOf([true], 'Accept'),
    acceptProcessing: Yup.bool().oneOf([true], 'Accept'),
    vSigner: Yup.string().email().required('Required'),
}

export default function HomeWebformular() {
    let [showloader, setb] = useState(false);
    const submitHandler = Util.submitHandler(useNavigate(), (val) => setb(val));

    return (
        <div style={{ backgroundImage: "url(/affimed_background.jpg)" }}
            className="bg-fixed bg-cover bg-no-repeat bg-center">
            <div className="flex flex-col flex-wrap items-center pt-10">

                <BasicForm
                    initialValues={InitialValues}
                    title={"TPDD QUESTIONNAIRE"}
                    submitHandler={submitHandler}
                    validationSchema={Yup.object().shape(validationSchema)}>
                    <Form>
                        <Section title="I. General Information">
                            <InputField
                                name="vCompanyName"
                                title="1. Company Name" />
                            <InputTextArea
                                name="vTypeOfCompany"
                                title="2. Type of company (e.g., privately owned, public)" />
                            <InputTextArea
                                name="vPlaceOfRegistration"
                                title="3. Place of registration" />
                            <InputTextArea
                                name="vNumberOfEmployees"
                                title="4. Approximate total number of Company employees (in FTEs)" />
                            <InputTextArea
                                name="vTotalCompanySales"
                                title="5. Approximate total of Company sales (in EUR)" />
                            <InputTextArea
                                name="vParentCompany"
                                title="6. Ultimate Parent Entity of Company (The controlling company of a Group of companies)" />
                            <InputTextArea
                                name="vShareholdersTenPercent"
                                title="7. Shareholders with a share of more than 10% in Company or Ultimate Parent Entity" />
                            <FileUploadField
                                name="vCorporateStructure"
                                title="8. Where available, overview over corporate
                                                structure and affiliates (attachment)"
                                withComment />

                            <TableField
                                name="vBoardMembers"
                                title="9. Board Members and Key Officer of the Company"
                                allowMultipleRows
                                columns={['Name', 'Title', 'Department', 'Phone No.', 'email address']} />
                            <TableField
                                name="vMainContactPerson"
                                title="10. Main contact persons (including Compliance, where applicable)"
                                allowMultipleRows
                                columns={['Name', 'Title', 'Department', 'Phone No.', 'email address']} />

                            <InputTextArea
                                name="vProposedEngagement"
                                title="11. Does the Company plan to use any other companies
                                                on the proposed engagement? If so, please specify third party and task." />
                        </Section>

                        <Section title="II. Integrity">
                            <InputTextArea
                                name="vInfluenceCompanyDecision"
                                title="12. To the best knowledge of the Company, has any of the
                                                Shareholders or any Company Board Member, Executive or employee 
                                                who would be in a position to influence a Company business decision, 
                                                which is relevant for the business relationship with Affimed, 
                                                or one of their Family Members ever served for or been employed by a government
                                                (also government agency etc.; 'politically exposed person')?" />

                            <InputTextArea
                                name="vCloseRelationship"
                                title="13. To the best knowledge of the Company, 
                                                has any of the Shareholders or any Company Board Member, 
                                                Executive or employees or any of their Family Members 
                                                a close personal or business relationship with any Affimed Board Member, 
                                                Executive or employee who would be in a position to influence 
                                                Affimed business decisions that provide a commercial advantage the individual 
                                                or the Affimed Board Member, Executive or employee
                                                ('conflict of interest')? If so, please provide details" />

                            <InputTextArea
                                name="vSubjectCriminalConviction"
                                title="14. Within the past 5 years, has the Company or,
                                                to the best knowledge of the Company, any of the Shareholders 
                                                or any Company Executive or employee who is relevant 
                                                for the business relationship with Affimed been subject 
                                                to any criminal conviction, investigation, 
                                                data protection (GDPR) inspection, or any similar legal proceeding? 
                                                If so, please provide details." />

                            <InputTextArea
                                name="vTradeSanctionList"
                                title="15. Within the past 5 years, has the Company or, 
                                                to the best knowledge of the Company, 
                                                any of the Shareholders or any Company Executive 
                                                or employee been put on a trade sanction list 
                                                (e.g., an OFAC list)? If so, please provide details." />

                        </Section>

                        <Section title="III. Compliance Program">
                            <InputTextArea
                                name="vComplianceProgramEthics"
                                title="16. Does the Company have a compliance/data protection program, 
                                                in particular a code of ethics or a similar policy? If so, please provide
                                                the respective information (including attachments)." />

                            <FileUploadField
                                name="vComplianceProgramEthicsAttachements"
                                title="17. Attachments to Question 16." />

                            <InputTextArea
                                name="vEmployeeComplianceTraining"
                                title="18. How often do you train your employees
                                                regarding your compliance / data protection program? 
                                                Please provide details." />

                            <InputTextArea
                                name="vCommitToCoC"
                                title={
                                    <>
                                        19. Does the Company commit to the values and standards set out in Affimed's Code of Conduct for Business Partners (see <a className="text-red-500" target="_blank" rel="noreferrer" href='https://www.affimed.com/wp-content/uploads/Affimed-2022-CodeOConduct-Partners-FINAL.pdf'>Affimed Code of Conduct</a>)? If not, please explain to what standards the Company commits itself (including attachments).
                                    </>
                                } />

                            <FileUploadField
                                name="vCommitToCoCAttachements"
                                title="20. Attachments to Question 19." />

                        </Section>

                        <Section title="IV. Other">
                            <InputTextArea
                                name="vSustainabilityESG"
                                title="21. Please provide details on any Sustainability 
                                                or ESG program of the Company and, where available, 
                                                certifications such as (including attachments)." />

                            <FileUploadField
                                name="vSustainabilityESGAttachements"
                                title="22. Attachments to Question 21." />

                            <InputTextArea
                                name="vCertifications"
                                title="23. Where available, please provide details 
                                                on any certifications of the Company's IT systems 
                                                (including attachments)." />

                            <FileUploadField
                                name="vCertificationsAttachments"
                                title="24. Attachments to Question 23." />

                            <CheckBox
                                name={"acceptForm"}
                                title={
                                    <>
                                        25. The Company confirms that, to its best knowledge, all responses are complete and accurate. In particular, Company confirms that it has a legal basis for providing Affimed with these data which, if required (e.g. because the processing requires a data subject's consent), includes the processing by Affimed as described in the Data Privacy Policy.
                                    </>
                                }
                                type={"checkbox"}
                                label="I Agree" />

                        </Section>

                        <Section title="V. Signing">
                            <InputField
                                name="vSigner"
                                title="26. Please enter your email address in order 
                                                to sign this document via Adobe Sign." />

                        </Section>

                        <SubmitButton showLoader={showloader} site={"TPDD"}/>
                    </Form>
                </BasicForm>

            </div>
        </div >
    );
}
