import { Field } from "formik";
import BasicField from "./BasicField";

export default function InputTextArea(props) {
    return (
        <div className="mt-10">
            <BasicField fname={props.name}>

                <label className="inline-block text-gray-700">{props.title}</label>
                <Field
                    component="textarea"
                    id={props.name}
                    name={props.name}
                    placeholder=""
                    className="flex flex-col w-full px-4 mt-4 py-1.5 text-base text-gray-900 bg-white bg-clip-padding border border-solid border-gray-400 transition ease-in-out m-0 hover:border-gray-600 focus:text-gray-700 focus:bg-white focus:outline-none"
                />
            </BasicField>
        </div>
    );
}