export default function TableRows({name, rowsData, deleteTableRows, handleChange, allowMultipleRows, columnNames }) {
    return (
        rowsData.map((data, index) => {
            return (
                <tr key={name+index}>
                    {
                        Object.entries(data).map(([k, v]) =>
                            <td key={'Key_'+k} className="">
                                <input
                                    type="text"
                                    className="w-full border border-solid border-gray-300 rounded px-2 py-0.5"
                                    value={v}
                                    name={k}
                                    onChange={(e) => (handleChange(index, e))} />
                            </td>
                        )
                    }

                    {allowMultipleRows &&
                        <td>
                            <button
                                className="w-8 h-8 rounded bg-red-400"
                                type="button"
                                onClick={() => (deleteTableRows(index))}>x</button>
                        </td>
                    }
                </tr>
            )
        })
    )
}