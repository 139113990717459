import { Field, useFormikContext } from "formik";
import React, { useReducer } from "react";
import { useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import BasicField from './BasicField'
import FileUploadRow from "./FileUploadRow";

function ErrorFallback({ error, resetErrorBoundary }) {
    return (
        <div role="alert">
            <p>Something went wron1:</p>
            <pre>{error.message}</pre>
            <button onClick={resetErrorBoundary}>Try again</button>
        </div>
    )
}

export default function FileUploadField(props) {

    const formikProps = useFormikContext();

    let initialState = { count: 0, free: {} }
    //initialState.free[props.name] = true;
    function theReducer(state, action) {
        switch (action.type) {
            case "increase":
                state.free[action.payload] = true
                return { ...state }
                break;
            case "decrease":
                if (state.free[action.payload]) {

                    state.free[action.payload] = false
                    if (Object.values(state.free).reduce((prev, current) => current ? prev + 1 : prev, 0) == 0) {
                        state.count++
                    }
                }
                return { ...state }
                break;
            default:
                throw new Error()
                break;
        }
    }
    const [state, dispatcher] = useReducer(theReducer, initialState)



    const onchanged = (formikProps) =>
        (event: React.ChangeEvent<HTMLInputElement>): void => {

            let unchanged_files = event.currentTarget.files;
            let renamed_files = [];
            let renamed_files_bonus = [];
            if (unchanged_files?.length) {
                dispatcher({ type: "decrease", payload: event.currentTarget.name });
            } else {
                dispatcher({ type: "increase", payload: event.currentTarget.name });
            }
            for (var i = 0; i < unchanged_files.length; i++) {
                renamed_files.push(new File([unchanged_files[i]], `${props.name}-${unchanged_files[i].name}`));
                renamed_files_bonus.push(`${props.name}-${unchanged_files[i].name}`);
            }
            let fileBag = { ...formikProps.values[props.name + "_filebag"] }
            fileBag[event.currentTarget.name] = renamed_files;

            updateFormik(fileBag, formikProps);
        };
    return (
        <div className="mt-10">
            <label htmlFor={props.name} className="text-gray-700 mb-4">{props.title}</label>

            {process.env.NODE_ENV !== 'production' &&
                <>
                    <pre>
                        {JSON.stringify(state)}
                    </pre>
                    <pre>
                        {JSON.stringify(formikProps.values[props.name + "_filebag"])}
                    </pre>
                </>
            }

            <ErrorBoundary FallbackComponent={ErrorFallback}>
                <FileUploadRow name={props.name} onchanged={onchanged} resetCallback={(() => {
                    dispatcher({ type: "increase", payload: props.name })
                    let fileBag = { ...formikProps.values[props.name + "_filebag"] }
                    fileBag[props.name] = [];

                    updateFormik(fileBag, formikProps);
                })} />
                {[...Array(state.count).keys()].map(element => {
                    return (<FileUploadRow name={props.name + "_" + element} key={props.name + "_" + element} onchanged={onchanged} resetCallback={(() => {
                        dispatcher({ type: "increase", payload: props.name + "_" + element })
                        let fileBag = { ...formikProps.values[props.name + "_filebag"] }
                        fileBag[props.name + "_" + element] = [];

                        updateFormik(fileBag, formikProps);
                    })} />)
                })}
            </ErrorBoundary>


            {props.withComment &&
                <div className="mt-4">
                    <BasicField fname={`${props.name}_comments`}>

                        <label htmlFor={`${props.name}_comments`} >Comment for files:</label>

                        <Field
                            type="text"
                            id={`${props.name}_comments`}
                            name={`${props.name}_comments`}
                            placeholder=""
                            className="flex flex-col w-full px-4 py-1.5 text-base text-gray-900 bg-white bg-clip-padding border border-solid border-gray-400 transition ease-in-out m-0 hover:border-gray-600 focus:text-gray-700 focus:bg-white focus:outline-none"
                        />
                    </BasicField>
                </div>
            }

        </div>
    );

    function updateFormik(fileBag: any, formikProps: any) {
        formikProps.setFieldValue(props.name + "_filebag", fileBag);

        formikProps.setFieldValue(props.name, Object.values(fileBag).reduce(
            (prev, cur) => {
                return prev.concat(cur);
            },
            []));
        const filenameArray = Object.values(fileBag).reduce(
            (prev, cur) => {
                return prev.concat(cur.map(
                    el => {
                        return el.name;
                    }
                ));
            },
            []);
        formikProps.setFieldValue(props.name, JSON.stringify(filenameArray));
        if (process.env.NODE_ENV !== 'production') console.log(filenameArray);

    }
}
