import CheckBox from "./CheckBox";
import { FallingLines } from 'react-loader-spinner'

export default function SubmitButton(props) {
    return (
        <div className="mt-8 mb-10 ml-8">
            <CheckBox
                name={"acceptProcessing"}
                title={""}
                type={"checkbox"}
                label=
                {
                    <>
                        I have read and understood <a className="text-red-500" target="_blank" rel="noreferrer" href="https://www.affimed.com/wp-content/uploads/Data-Privacy-Policy-TPDD_final-version.pdf">how Affimed processes personal data in the context of the {props.site} Questionnaire.</a>
                    </>
                } />
            <button type="submit"
                className="text-white bg-[#054852] hover:bg-[#0b383f] focus:ring-2 focus:ring-blue-300 font-medium text-sm px-5 py-2.5 mr-2 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800">
                Submit
            </button>
            {props.showLoader &&
                (<div>
                    <label htmlFor="fileup">Uploading Files...</label>
                    <div>
                        <FallingLines color="pink" id="fileup">Uploading Files...</FallingLines>
                    </div>
                </div>
                )}
        </div>
    );
}