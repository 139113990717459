import { Route, Routes } from 'react-router-dom';

import Success from '../Success';
import HomeTPDD from '../TPDD/HomeTPDD';



export default function Routings() {
  return (

        <Routes>
          <Route path="/" element={<HomeTPDD />} />
          <Route path="/success" element={<Success title={"TPDD QUESTIONNAIRE"} />} />
        </Routes>
  );
}
