import { useField } from "formik";
import React from "react";
import BasicField from "./BasicField";

export default function InputField({ title, ...props }) {
    const [field, meta] = useField(props)

    return (
        <div className="mt-10">
            <BasicField fname={field.name}>

                <label htmlFor={field.name} className="inline-block text-gray-700">{title}</label>
                <input
                    id={field.name}
                    className={`flex flex-col w-full px-4 mt-4 py-1.5 text-base text-gray-900 bg-white bg-clip-padding border border-solid border-gray-400 transition ease-in-out m-0 hover:border-gray-600 focus:text-gray-700 focus:bg-white focus:outline-none" ${meta.touched && meta.error && 'is-invalid'}`}
                    {...field}
                    {...props}
                    autoComplete='off' />
            </BasicField>
        </div>
    );
}