import { useFormikContext } from "formik";
import React from "react";
import { useState } from "react";
import BasicField from "./BasicField";

import TableRows from "./TableRow";


export default function TableField(props) {
    const fixedColumnNames = [...props.columns]// why this ?! .map(e => e.toLowerCase()).map(e => e.replace(/\s/g, ''))

    const state_dict = {}
    for (let i = 0; i < fixedColumnNames.length; i++) {
        state_dict[fixedColumnNames[i]] = ""
    }

    const formikProps = useFormikContext();
    const [rowsData, setRowsData] = useState([{ ...state_dict }]);

    const addTableRows = () => {
        const rowsInput = { ...state_dict }
        setRowsData([...rowsData, rowsInput]);
    }

    const deleteTableRows = (index) => {
        const rows = [...rowsData];

        rows.splice(index, 1);
        setRowsData(rows);

        recreateFullStringFromTableFields();
    }

    const handleChange = (index, e) => {
        const { name, value } = e.target;
        const rowsInput = [...rowsData];

        rowsInput[index][name] = value;
        setRowsData(rowsInput);
        //SetTimeout allows the keystroke to be drawn when holding down a key.
        setTimeout(recreateFullStringFromTableFields);
    }

    const recreateFullStringFromTableFields = () => {
        let full_string = "";
        full_string = rowsData.map((row) => Object.entries(row).map(([name, value]) => value !== undefined ? `${name}: ${value}` : `${name}: `).join(', ')).join('\n\n')

        formikProps.setFieldValue(props.name, full_string);
    }

    return (
        <div className="mt-10">
            <BasicField fname={props.name}>

                <label htmlFor={props.name} className="inline-block text-gray-700">{props.title}</label>
                <table className="mt-4 bg-gray-400;">
                    <thead>
                        <tr>
                            {props.columns.map((column) => (
                                <th key={column} className="">{column}</th>
                            ))}

                            {props.allowMultipleRows &&
                                <th>
                                    <button type="button" className="bg-gray-800 text-white w-8 h-8 rounded" onClick={addTableRows}>+</button>
                                </th>
                            }
                        </tr>
                    </thead>
                    <tbody>
                        <TableRows
                            name={props.name}
                            rowsData={rowsData}
                            deleteTableRows={deleteTableRows}
                            handleChange={handleChange}
                            allowMultipleRows={props.allowMultipleRows}
                            columnNames={fixedColumnNames} />
                    </tbody>
                </table>
            </BasicField>

        </div>
    );
}