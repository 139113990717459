const sharepoint_logic_app_endpoint = "https://prod-15.germanywestcentral.logic.azure.com:443/workflows/704013a689ab44aabd603912b5c7dcd2/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=L7WHMmWRhgJmeDV0aVr_jLlDhAKK2vcxdYyxAxeufG8";
const blob_directory_logic_app_endpoint = "https://prod-16.germanywestcentral.logic.azure.com:443/workflows/9f03e1f9448e4bd19d9042e9988d9a5c/triggers/manual/paths/invoke?api-version=2016-10-01&sp=%2Ftriggers%2Fmanual%2Frun&sv=1.0&sig=mxT7iwDRqj6tmyMjTxvFlP3idd6696mGwG9LrLTPDS4";

const static_sas_key = "?sv=2021-06-08&ss=b&srt=co&sp=rwlactfx&se=2024-12-31T00:45:18Z&st=2022-07-28T15:45:18Z&spr=https&sig=ajQR255chTXCzIG4mMuUaOk6F5CL%2FP5WEy6eA8rSq%2Fs%3D";

const blob_url = "https://tpddformularuploaddata.blob.core.windows.net";
const container = 'formular-files';


const Settings = {
    'sharepoint_endpoint': sharepoint_logic_app_endpoint,
    'blob_endpoint': blob_directory_logic_app_endpoint,
    'sas_key': static_sas_key,
    'blob_url': blob_url,
    'container': container
}

const InitialValues = {
    vCompanyName: '',
    vTypeOfCompany: '',
    vPlaceOfRegistration: '',

    vNumberOfEmployees: '',
    vTotalCompanySales: '',
    vParentCompany: '',
    vShareholdersTenPercent: '',
    vCorporateStructure: [],
    vCorporateStructure_comments: '',
    vBoardMembers: '',
    vMainContactPerson: '',
    vProposedEngagement: '',

    vInfluenceCompanyDecision: '',
    vCloseRelationship: '',
    vSubjectCriminalConviction: '',
    vTradeSanctionList: '',

    vComplianceProgramEthics: '',
    vComplianceProgramEthicsAttachements: [],
    vEmployeeComplianceTraining: '',
    vCommitToCoC: '',
    vCommitToCoCAttachements: [],

    vSustainabilityESG: '',
    vSustainabilityESGAttachements: [],
    vCertifications: '',
    vCertificationsAttachments: [],

    acceptForm: false,
    acceptProcessing:false,
    vSigner: '',
}

export { Settings, InitialValues }